body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markdown-document pre {
  background-color: #F5F5F5;
  padding: 1em;
  border: 1px solid #A0A0A0;
}

.markdown-document li p {
  margin-bottom: 0
}

.mainmenu-height {
  height: 72px;
}

/* uses mainmenu height (72px) */
.sidebar-mainmenu-offset {
  overflow-y: auto;
  -ms-overflow-y: auto;
  top: 72px;
  height: calc(100vh - 72px);
}

/* uses mainmenu height (72px) and parent div padding p-2 which is equal to 0.5rem according to bootstrap */
.toc-mainmenu-offset {
  overflow-y: auto;
  -ms-overflow-y: auto;
  top: calc(72px + 0.5rem);
  height: calc(100vh - 72px - 0.5rem);
}

/* uses mainmenu height as margin-top and height of invisible element before the heading anchor
   allows scrolling to the anchor without it being placed behind the mainmenu
   The double h1::before fixes an issue with the first not being recognized by the browser */
.markdown-document h1::before, h1::before, h2::before, h3::before, h4::before, h5::before, h6::before {
  display: block;
  content: " ";
  margin-top: -72px;
  height: 72px;
  visibility: hidden;
  pointer-events: none;
}