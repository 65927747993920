.nav-tabs > .nav-link.active {
    color: black;
    border-width: 4px;
    border-color: #dee2e6 #dee2e6 #fff
}
.nav-tabs > .nav-link {
    color: black;
}

.nav-tabs > .nav-item {
    margin-bottom: -4px;
}

.nav-tabs {
    border-width: 4px;
}

.border-medium {
    border-width: 4px !important;
}